import React, { useState, useRef, useEffect } from 'react';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { FaLightbulb } from 'react-icons/fa';
import remarkMath from "remark-math";
import { Ellipsis } from 'react-css-spinners'
import ENDPOINTS from '../../config';
import { MemoizedReactMarkdown } from '../Tools/markdown';
import CodeBlock from '../Tools/codeblock';
import { ChatMessage } from '../../Entity/ChatMessage';


interface PromptMessageComponentProps {
  messages: ChatMessage[];
  lastMessageRef: React.MutableRefObject<HTMLDivElement | null>;
  isLoading: boolean;
}

const PromptMessageComponent: React.FC<PromptMessageComponentProps> = ({ messages, lastMessageRef, isLoading }) => {
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);
  const commentFormElementRef = useRef<HTMLDivElement>(null);
  const [selectedImage, setSelectedImage] = useState<{ src: string; description?: string } | null>(null);



  const copyToClipboard = (message: string) => {
    if (message) {
      navigator.clipboard.writeText(message)
        .then(() => {
          console.log('Text copied to clipboard:', message);
          // Aquí podrías mostrar un mensaje de confirmación si lo deseas
        })
        .catch(err => {
          console.error('Error copying text to clipboard:', err);
        });
    }
  };

  const updateMessageEvaluation = (messageId: number, evaluation: string) => {
    console.log(`Updating evaluation for message ${messageId} to ${evaluation}`);
    if (evaluation === 'negative') {
      setTimeout(() => {
        if (commentFormElementRef.current) {
          commentFormElementRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
      setSelectedMessageId(messageId); // Almacena el ID del mensaje para enviar el comentario junto con la evaluación
      setShowCommentForm(true); // Mostrar el formulario de comentario
    } else {
      sendEvaluation(messageId, evaluation); // Envía la evaluación directamente para otros casos
    }
  };

  const sendEvaluation = async (messageId: number, evaluation: string) => {
    try {
      const message_id = messageId.toString();
      const body = {
        evaluation,
        comment
      };
      const token = localStorage.getItem("token");
      const response = await fetch(ENDPOINTS.CHAT_MESSAGE_EVALUATION(message_id), {
        method: 'PUT',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Failed to update evaluation');
      }
      setAlertMessage('Evaluation updated successfully');
      setShowCommentForm(false);
      setComment('');
      setTimeout(() => {
        setAlertMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Error updating evaluation:', error);
    }
  };
  const submitComment = async () => {
    if (selectedMessageId !== null && comment.trim() !== '') {
      sendEvaluation(selectedMessageId, 'negative');
    }
  };
  return (
    <div className="flex-1 overflow-y-auto rounded-xl bg-slate-200 p-4 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7">
      {alertMessage && (
        <div className="fixed top-4 right-4 z-50">
          <div className="flex items-center justify-between bg-green-500 text-white rounded-md shadow-md px-4 py-2">
            <span className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM12 8a1 1 0 11-2 0 1 1 0 012 0zm-1 2a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span>{alertMessage}</span>
          </div>
        </div>
      )}
      {messages.map((message, index) => {
        // Determine if the current message is the last in the list
        const isLastMessage = index === messages.length - 1;

        // Reference for the last message, use `lastMessageRef` if it is the last message
        const messageRef = isLastMessage ? lastMessageRef : null;

        // Display user messages
        if (message.sender === "user") {
          return (
            <div key={index} className="flex px-2 py-4 sm:px-4" ref={messageRef}>
              <img
                className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                src="https://dummyimage.com/256x256/363536/ffffff&text=U"
                alt="User Avatar"
              />
              <div className="flex items-center">
                <p>{message.message}</p>
              </div>
            </div>
          );
        }
        // Display assistant responses
        if (message.sender === 'assistant') {
          return (
            <div>
              <div key={index} className="mb-4 flex rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 sm:px-4" ref={messageRef}>
                <FaLightbulb className="mr-2 h-8 w-8 text-yellow-500" />
                <div className="flex   items-center w-full">
                  <div className="flex-1 px-1 ml-4  overflow-hidden">
                    <MemoizedReactMarkdown
                      remarkPlugins={[remarkGfm, remarkMath]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        table({ node, ...props }: { node: any, props: any }) {
                          return (
                            <table className="markdown-table" {...props} />
                          );
                        },
                        th({ node, ...props }: { node: any, props: any }) {
                          return (
                            <th className="markdown-table-th" {...props} />
                          );
                        },
                        td({ node, ...props }: { node: any, props: any }) {
                          return (
                            <td className="markdown-table-td" {...props} />
                          );
                        },
                        code({ inline, className, children, ...props }: { inline: boolean; className?: string; children: React.ReactNode }) {
                          const match = /language-(\w+)/.exec(className || '');
                          return !inline ? (
                            <CodeBlock
                              key={Math.random()}
                              language={(match && match[1]) || ''}
                              value={String(children).replace(/\n$/, '')}
                              {...props}
                            />
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          );
                        },
                      }}
                    >
                      {message.message}
                    </MemoizedReactMarkdown>
                  </div>

                </div>
                <div
                  className="mt-4 flex flex-row justify-start gap-x-2 text-slate-500 lg:mt-0 margin-block-start: auto;"
                  style={{ alignItems: 'end' }}>
                  <button className="hover:text-blue-600" onClick={() => updateMessageEvaluation(message.id, 'positive')}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"></path>
                    </svg>
                  </button>
                  <button className="hover:text-blue-600" type="button" onClick={() => updateMessageEvaluation(message.id, 'negative')}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3"
                      ></path>
                    </svg>
                  </button>
                  <button className="hover:text-blue-600" type="button" onClick={() => copyToClipboard(message.message)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
                      ></path>
                      <path
                        d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                      ></path>
                    </svg>
                  </button>
                </div>


              </div>


              {message.reference && (
                <div className="w-full max-w-3xl text-xs text-slate-600 dark:text-slate-300 sm:text-sm md:text-base">
                  <span className=" p-1 text-white opacity-90 sm:p-2">References:</span>
                  {message.reference.map((reference, index) => (
                    <button className="block w-auto rounded-lg bg-slate-200 p-1.2 mb-1 text-[12px] text-left hover:bg-blue-600 hover:text-slate-200 dark:bg-slate-900 dark:hover:bg-blue-600 dark:hover:text-slate-50">
                      <span className="ml-2 p-1 text-white opacity-90 sm:p-2">{reference}</span>
                    </button>
                  ))}
                </div>
              )}
              {/*  Si en message.images hay arreglo de imagenes, las mostramos*/}
              {message.images && (

                <div className="flex flex-wrap gap-2 mt-4">

                  {message.images.map((image, index) => (
                    <img
                      key={index}
                      src={`data:image/png;base64,${image.src}`}
                      alt="Assistant response"
                      style={{
                        cursor: 'pointer', // Cambia el cursor al pasar sobre la imagen
                        transition: 'transform 0.2s ease-in-out', // Agrega transición para el efecto
                        transform: 'scale(1)', // Escala inicial
                      }}
                      className="w-32 h-32 object-cover rounded-lg shadow-lg"
                      onClick={() => setSelectedImage({ src: `data:image/png;base64,${image.src}`, description: image.source })}
                      onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)' }} // Agranda la imagen al pasar el mouse
                      onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)' }} // Restaura el tamaño al salir el mouse
                    />
                  ))}
                </div>
              )}
            </div>
          );
        }
        return null;
      })}
      {isLoading && (
        <div className="mb-4 flex rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 sm:px-4" >
          <Ellipsis />
        </div>
      )}
      {showCommentForm && (
        <div className="mb-4 rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 flex flex-col">
          <div className="flex justify-end mb-2">
            <button
              className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
              onClick={() => setShowCommentForm(false)}
              aria-label="Close"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <h2 className="text-lg font-semibold text-slate-900 dark:text-slate-300" style={{ marginBottom: '2px' }}>
            Please provide feedback
          </h2>
          <textarea
            className="block w-full rounded-md py-1.5 pl-10 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 dark:bg-gray-900 dark:text-white dark:focus:ring-blue-500 sm:text-sm sm:leading-6 border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-opacity-50 dark:border-gray-700 dark:focus:border-blue-500 dark:shadow-none"
            rows={4}
            placeholder="Enter your comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="mt-2">
            <button
              className="rounded-lg bg-blue-700 px-4 py-2 text-sm font-medium text-slate-200 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:text-base"
              onClick={submitComment}
            >
              Send Feedback
            </button>
          </div>
        </div>
      )}
      {selectedImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
          onClick={() => setSelectedImage(null)}
        >
          <div
            className="relative"
            onClick={(e) => e.stopPropagation()} // Detiene la propagación del clic
          >
            <img
              src={selectedImage.src}
              alt="Zoomed"
              style={{
                maxWidth: '90vw', // No excede el 90% del ancho de la ventana
                maxHeight: '90vh' // No excede el 90% de la altura de la ventana
              }}
              className="max-w-full max-h-full rounded-lg"
              onClick={() => setSelectedImage(null)} // Cierra el modal al hacer clic en la imagen
            />
            {selectedImage.description && (
              <div className="absolute top-0 left-0 text-white bg-gray-700 p-1">
                <span>{selectedImage.description}</span>
              </div>
            )}
            <button
              className="absolute top-0 right-0 text-white bg-gray-700 rounded-full p-1"
              onClick={() => setSelectedImage(null)} // Cierra el modal al hacer clic en el botón de cerrar
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default PromptMessageComponent;
