import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import ENDPOINTS from '../../config';
import { DocumentRAG } from '../../Entity/DocumentRAG';
import { useTranslation } from 'react-i18next';
import PDFViewer from '../ChatComponents/PdfViewerComponent';


const fadeInAnimation = {
  animation: 'fadeIn 1.5s ease-in-out',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
};

const ScrollableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100vh',
  padding: '10px',
  marginTop: '0px',
  alignItems: 'center',
});



const StyledCard = styled('li')(({ theme }) => ({
  display: 'flex', // Cambiado a flex para imagen a la izquierda, texto a la derecha
  flexDirection: 'row',
  maxWidth: 'sm',
  cursor: 'pointer',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '10px',
  marginBottom: '20px',
  padding: '15px',
  ...fadeInAnimation,
  opacity: 0,
}));

const ImageContainer = styled('div')({
  width: '150px',
  height: '150px',
  marginRight: '20px',
  backgroundSize: 'contain', // Cambiado a 'contain' para mantener la relación de aspecto de la imagen
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat', // Evita que la imagen se repita si es más pequeña
  borderRadius: '10px',
  flexShrink: 0, // Asegura que el contenedor no se encoja si el contenido es mayor
});

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
});

const Title = styled('h3')({
  margin: 0,
  fontSize: '1.25rem',
  fontWeight: 'bold',
  color: 'rgb(229, 231, 235)',
});

const Description = styled('p')({
  margin: '10px 0',
  fontSize: '1rem',
  color: '#555',
});

const MetaData = styled('span')({
  fontSize: '0.875rem',
  color: '#888',
});

interface CardListProps {
  documents: DocumentRAG[];
  fetchDocuments: (page: number, search: string) => Promise<void>;
  handleScroll: () => void;
  isDocumentsLoading: boolean;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const CardList: React.FC<CardListProps> = ({
  documents,
  fetchDocuments,
  handleScroll,
  isDocumentsLoading,
  searchTerm,
  setSearchTerm,
}) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
 
  const [selectedDocument, setSelectedDocument] = useState<DocumentRAG | null>(null);
 
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);
  

  const handleCardClick = (document: DocumentRAG) => {
    setSelectedDocument(document);

  };

  const handleCloseModal = () => {
    setSelectedDocument(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    fetchDocuments(1, event.target.value);
  };

  return (
    <>          
      {selectedDocument ? (
        <PDFViewer
          open={Boolean(selectedDocument)}
          onClose={handleCloseModal}
          documentUrl={ENDPOINTS.PDF_VIEWER(selectedDocument.id || 0)}
          documentName={selectedDocument.name}
        />
      ) : (
        <> 
        <div className="relative w-full mb-5 px-2 py-2">
        <h1 className="text-xl font-bold text-slate-800 dark:text-slate-200">{t('document.documents')}</h1>
        <input
          type="text"
          className="w-full rounded-lg border border-slate-300 bg-slate-50 p-3 pr-10 text-sm text-slate-800 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-slate-700 dark:bg-slate-900 dark:text-slate-200"
          placeholder={t('document.search_by_title')}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
        <ScrollableContainer onScroll={handleScroll}>
          
            <ul className="w-full">
              {documents.map((document, index) => (
                <StyledCard
                  key={document.id}
                  style={{
                    animationDelay: isVisible ? `${index * 0.1}s` : '0s',
                    opacity: isVisible ? 1 : 0,
                  }}
                  onClick={() => handleCardClick(document)}
                  className="bg-slate-50 dark:bg-slate-900 rounded-lg shadow-sm  w-full"
                >
                  <ImageContainer
                    style={{
                      backgroundImage: `url(${document.preview_image ? `data:image/png;base64,${document.preview_image}` : 'https://images.vexels.com/content/135821/preview/document-file-icon-5b3ad4.png'})`
                    }}
                  ></ImageContainer>
                  <ContentContainer>
                    <Title>{document.name}</Title>
                    <MetaData>{t('document.created_at')}: </MetaData>
                    <Description>{document.description}</Description>
                  </ContentContainer>
                </StyledCard>
              ))}
            </ul>

            {isDocumentsLoading && <div>{t('document.loading_more_documents')}</div>}

        </ScrollableContainer>
        </>
      )}
    </>
  );
};

export default CardList;
