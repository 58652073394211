import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { PromptTemplate } from '../../Entity/PromptTemplate';
import { cancelAudioRecording, startAudioRecording, stopAudioRecording } from '../Tools/audio-recording';
import ENDPOINTS from '../../config';
import { FaFileAlt } from "react-icons/fa";
import Switch, { SwitchProps } from '@mui/material/Switch';

interface PromptInputProps {
    onSendMessage: (newMessage: string, selectedFile: any, configuration: any) => void;
    promptTemplates: PromptTemplate[]; // Agrega promptTemplates
    setPromptTemplates: React.Dispatch<React.SetStateAction<PromptTemplate[]>>; // Agrega setPromptTemplates
    isLoading: boolean;
}

const PromptInputComponent: React.FC<PromptInputProps> = ({ onSendMessage, promptTemplates, setPromptTemplates, isLoading }) => {
    const [inputValue, setInputValue] = useState('');
    const [showList, setShowList] = useState(false);


    const [selectedTemplate, setSelectedTemplate] = useState<PromptTemplate | null>(null);
    const [variableValues, setVariableValues] = useState<{ [key: string]: string }>({});

    const allItems = promptTemplates.map(template => template.name);
    const filteredItems = allItems.filter(item => item.toLowerCase().includes(inputValue.toLowerCase().substring(1)));

    const [isRecording, setIsRecording] = useState(false);
    const [recordingNotSupported, setRecordingNotSupported] = useState(false);
    const [isLoadingRecording, setIsLoadingRecording] = useState(false);
    const [elapsedTime, setElapsedTime] = useState('00:00');
    const [selectedFile, setSelectedFile] = useState<File | null>(null); // Estado para el archivo seleccionado

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    //internet acces bolean and document acces bolean
    const [internetAccess, setInternetAccess] = useState(true);
    const [documentAccess, setDocumentAccess] = useState(true);

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined; // Declare timer outside the if statement
        if (isRecording) {
            const startTime = Date.now();
            timer = setInterval(() => {
                const elapsedTimeInMs = Date.now() - startTime;
                const elapsedSeconds = Math.floor(elapsedTimeInMs / 1000);
                const minutes = Math.floor(elapsedSeconds / 60).toString().padStart(2, '0');
                const seconds = (elapsedSeconds % 60).toString().padStart(2, '0');
                setElapsedTime(`${minutes}:${seconds}`);
            }, 1000);
        } else {
            if (timer) { // Check if timer is defined before clearing it
                clearInterval(timer);
            }
            setElapsedTime('00:00');
        }
        return () => {
            if (timer) { // Check if timer is defined before clearing it
                clearInterval(timer);
            }
        };
    }, [isRecording]);


    const handleStartRecording = () => {
        setIsLoadingRecording(true);
        startAudioRecording(
            () => {
                setIsRecording(true);
                setIsLoadingRecording(false);
            },
            () => {
                setRecordingNotSupported(true);
                setIsLoadingRecording(false);
            }
        );
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        setIsLoadingRecording(true);
        stopAudioRecording((audioBlob: Blob) => {
            setIsLoadingRecording(false);
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.wav');
            const token = localStorage.getItem("token");
            axios.post(ENDPOINTS.UPLOAD_AUDIO, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${token}`
                },
            })
                .then((response) => {
                    setInputValue(response.data.message);
                })
                .catch((error) => {
                    console.error('Error uploading audio:', error);
                });
        });
    };

    const handleCancelRecording = () => {
        setIsRecording(false);
        cancelAudioRecording();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        setShowList(newValue.startsWith('/'));
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
            onSendMessage(inputValue.trim(), selectedFile, { "internetAccess": internetAccess, "documentAccess": documentAccess });
            setInputValue('');
            setShowList(false);
            setFileName(null);
        }
    };

    const handleTemplateSelect = (itemName: string) => {
        const selected = promptTemplates.find(template => template.name === itemName);
        if (selected) {
            const variables = selected.content.match(/{{\s*[\w]+\s*}}/g);
            if (variables) {
                setSelectedTemplate(selected);
                setVariableValues(
                    variables.reduce((acc, variable) => {
                        acc[variable] = '';
                        return acc;
                    }, {} as { [key: string]: string })
                );
            } else {
                setInputValue(selected.content);
                setShowList(false);
            }
        }
    };

    const handleVariableChange = (variable: string, value: string) => {
        setVariableValues(prevValues => ({ ...prevValues, [variable]: value }));
    };

    const handleAcceptVariables = () => {
        let finalContent = selectedTemplate?.content || '';
        for (const [variable, value] of Object.entries(variableValues)) {
            finalContent = finalContent.replace(new RegExp(variable, 'g'), value);
        }
        setInputValue(finalContent);
        setSelectedTemplate(null);
        setVariableValues({});
    };

    const handleCancelVariables = () => {
        setSelectedTemplate(null);
        setVariableValues({});
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleFormSubmit(event as unknown as React.FormEvent);
        }
    };



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const handleFileButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const switchDocumentAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentAccess(event.target.checked);
    }

    const switchInternetAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternetAccess(event.target.checked);
    }

    return (
        <div>
            <form className="mt-2" onSubmit={handleFormSubmit}>
                {selectedTemplate && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                        <div className="bg-slate-50 dark:bg-slate-700 rounded-lg shadow-sm p-6 max-w-md w-full">
                            <h2 className="text-xl font-semibold mb-4">Fill in the variables</h2>
                            {Object.keys(variableValues).map((variable, index) => (
                                <div className="mb-4" key={index}>
                                    <label className="block mb-2 text-sm font-medium" htmlFor={`var-${index}`}>
                                        {variable.replace(/{{\s*|\s*}}/g, '')}:
                                    </label>
                                    <input
                                        id={`var-${index}`}
                                        type="text"
                                        className="w-full px-3 py-2 border rounded-lg dark:bg-slate-900"
                                        value={variableValues[variable]}
                                        onChange={(e) => handleVariableChange(variable, e.target.value)}
                                    />
                                </div>
                            ))}
                            <div className="flex justify-end">
                                <button
                                    className="mr-2 px-4 py-2 bg-gray-300 rounded-lg dark:bg-slate-900"
                                    onClick={handleCancelVariables}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                                    onClick={handleAcceptVariables}
                                >
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex items-center justify-end mb-4 space-x-4" style={{ marginTop: '0', marginRight: '12px', marginBottom: '0px' }}>
                    <div className="flex items-center">
                        <label className="text-sm font-medium">
                            Internet Search:
                        </label>
                        <Switch color="primary" onChange={switchInternetAccess} checked={internetAccess} />
                    </div>
                    <div className="flex items-center">
                        <label className="text-sm font-medium">
                            Documents Search:
                        </label>
                        <Switch color="primary" onChange={switchDocumentAccess} checked={documentAccess} />
                    </div>
                </div>


                <label htmlFor="chat-input" className="sr-only">Enter your prompt</label>

                {showList && (
                    <div className="top-0 left-0 right-0 bg-white rounded shadow-md z-10" style={{ marginLeft: '12px', marginRight: '12px', marginBottom: '12px', overflowY: 'auto', maxHeight: '200px' }}>
                        <div className="px-1 py-2 bg-gray-900 text-slate-200">Prompt Template</div>
                        <ul className="p-2 dark:bg-gray-700">
                            {filteredItems.map((item, index) => (
                                <li
                                    key={index}
                                    className="py-1 px-2 hover:bg-gray-500 dark:hover:bg-gray-600 border-b border-white-200 dark:border-white-500"
                                    onClick={() => handleTemplateSelect(item)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="text-gray-900 placeholder:text-gray-400 focus:ring-2  rounded-md  focus:ring-blue-500 dark:bg-gray-900 dark:text-white dark:focus:ring-blue-500 p-3" style={{ marginLeft: '12px', marginRight: '12px', marginBottom: '12px' }}>

                    {!isRecording ? (
                        <div className="relative" >
                            {fileName && (
                                <div className="mb-2 flex items-center">
                                    <FaFileAlt />

                                    <span>{fileName}</span>
                                </div>
                            )}

                            <textarea
                                id="chat-input"
                                name="chat-input"
                                rows={3}
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                className="block w-full rounded-md py-1.5 pl-10 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 dark:bg-gray-900 dark:text-white dark:focus:ring-blue-500 sm:text-sm sm:leading-6 border-0 shadow-inner dark:shadow-none"
                                placeholder={isLoading ? 'Wait for the response...' : 'Enter your prompt'}
                                disabled={isLoadingRecording || isLoading}

                            />

                        </div>
                    ) : (
                        <div className="relative flex items-center justify-between rounded-md text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 dark:bg-gray-900 dark:text-white dark:focus:ring-blue-500 p-3" style={{ marginLeft: '12px', marginRight: '12px', marginBottom: '12px', height: '80px' }}>
                            <button
                                type="button"
                                onClick={handleCancelRecording}
                                className="text-red-600 hover:text-red-800"

                            > <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                                Cancel
                            </button>
                            <span className="text-gray-700 dark:text-gray-300">
                                Recording Time: {elapsedTime}
                            </span>
                            <button
                                type="button"
                                onClick={handleStopRecording}
                                className="text-blue-600 hover:text-blue-800"
                            > <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 14l11 -11"></path>
                                    <path
                                        d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"
                                    ></path>
                                </svg>
                                Send
                            </button>
                        </div>
                    )}
                    {!isRecording && (
                        <div className="flex items-center space-x-2 ml-2 justify-end" style={{ marginTop: '2px' }}>
                            <button
                                type="button"
                                className="px-3 py-2  text-white rounded-lg"
                                onClick={handleFileButtonClick}
                            ><svg
                                aria-hidden="true"
                                className="h-8 w-8"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                strokeWidth="1"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M12.5,23 C15.54,23 18,20.54 18,17.5 L18,6 L16.5,6 L16.5,17.5 C16.5,19.71 14.71,21.5 12.5,21.5 C10.29,21.5 8.5,19.71 8.5,17.5 L8.5,5 C8.5,3.62 9.62,2.5 11,2.5 C12.38,2.5 13.5,3.62 13.5,5 L13.5,15.5 C13.5,16.05 13.05,16.5 12.5,16.5 C11.95,16.5 11.5,16.05 11.5,15.5 L11.5,6 L10,6 L10,15.5 C10,16.88 11.12,18 12.5,18 C13.88,18 15,16.88 15,15.5 L15,5 C15,2.79 13.21,1 11,1 C8.79,1 7,2.79 7,5 L7,17.5 C7,20.54 9.46,23 12.5,23 Z"
                                    ></path>
                                </svg>
                            </button>
                            <input
                                ref={fileInputRef}
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            <button
                                type="button"
                                disabled={isLoading}
                                onClick={handleStartRecording}
                                className="text-slate-500 hover:text-blue-600 dark:text-slate-400 dark:hover:text-blue-600"
                                style={{ marginRight: '12px' }}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="h-8 w-8"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M9 2m0 3a3 3 0 0 1 3 -3h0a3 3 0 0 1 3 3v5a3 3 0 0 1 -3 3h0a3 3 0 0 1 -3 -3z"
                                    ></path>
                                    <path d="M5 10a7 7 0 0 0 14 0"></path>
                                    <path d="M10 21l4 0"></path>
                                </svg>
                            </button>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="rounded-lg bg-blue-700 px-4 py-2 text-sm font-medium text-slate-200 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:text-base"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 14l11 -11"></path>
                                    <path
                                        d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
                {recordingNotSupported && (
                    <div className="text-red-500" style={{ marginLeft: '12px', marginRight: '12px' }}>
                        Audio recording is not supported in this browser.
                    </div>
                )}
            </form>
        </div>
    );
};
export default PromptInputComponent;
