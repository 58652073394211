import React, { useState, useRef, useEffect } from "react";
import PromptMessageComponent from "./PromptMessageComponent";
import PromptInputComponent from "./PromptInputComponent";
import PDFViewer from "./PdfViewerComponent";
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { PromptTemplate } from "../../Entity/PromptTemplate";
import { ChatMessage } from "../../Entity/ChatMessage";
import ENDPOINTS from "../../config";
import CardList from "../DocumentComponents/CardList";

import { styled } from '@mui/system';
import { DocumentRAG } from "../../Entity/DocumentRAG";

const CustomPanelResizeHandle = styled(PanelResizeHandle)(({ theme }) => ({
  width: '10px',  // Anchura de la manija de arrastre
  backgroundColor: '#080b13',
  cursor: 'col-resize',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  transition: 'background-color 0.3s ease, width 0.3s ease',
  '&:hover': {
    backgroundColor: '#080b13',
    width: '12px',
  },
  '&::before': {
    content: '""',
    width: '2px',
    height: '50%',
    backgroundColor: '#333',
    borderRadius: '2px',
    transition: 'height 0.3s ease',
  },
  '&:hover::before': {
    height: '80%',
  },
}));


interface ChatComponentProps {
  promptTemplates: PromptTemplate[];
  setPromptTemplates: React.Dispatch<React.SetStateAction<PromptTemplate[]>>;
  messages: ChatMessage[];
  updateMessages: (messages: ChatMessage[]) => void;
  historyId: number | null;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  promptTemplates,
  setPromptTemplates,
  messages,
  updateMessages,
  historyId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const logoUrl = process.env.REACT_APP_LOGO_URL || '';
  const nameOfCompany = process.env.REACT_APP_COMPANY_NAME || '';
  const [documents, setDocuments] = useState<DocumentRAG[]>([]);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);

  useEffect(() => {
    if (historyId !== null) {
      console.log(`Current historyId: ${historyId}`);
    }
  }, [historyId]);

  useEffect(() => { }, [messages, updateMessages]);
  useEffect(() => {
    fetchDocuments(page, searchTerm);
  }, [page, searchTerm]);


  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const isBase64Image = (str: string) => {
    const regex = /^[A-Za-z0-9+/]+[=]{0,2}$/;
    return regex.test(str);
  };
  // Función para manejar el envío de un nuevo mensaje
  const handleSendMessage = async (newMessage: string, selectedFile: any, configuration: any) => {
    try {
      setIsLoading(true);
      // Agregar el nuevo mensaje del usuario a los mensajes
      updateMessages([...messages, { id: 0, sender: "user", message: newMessage, images: [] }]);
      const token = localStorage.getItem("token");
      // Realizar la petición a la API de OpenAI con el nuevo mensaje
      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      formData.append('chat_message', JSON.stringify({ message: newMessage, sender: "user", id: 0 }));
      formData.append('chat_history_id', JSON.stringify(historyId || 0));
      formData.append('chat_history_message', JSON.stringify(messages));
      formData.append('configuration', JSON.stringify(configuration));


      const response = await fetch(ENDPOINTS.ASK_QUESTION, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: formData,
      });

      const data = await response.json();
      const answer = data.answer.answer; // Extraer la respuesta del objeto
      //obtener solo las imagenes de data.answer.contex donde page_content sea una imagen
      const images: { src: string, source: string }[] = [];
      const reference: string[] = [];
      let fileNameSet = new Set();
      const chat_history_id = data.answer.chat_history_id;
      const context = data.answer.context
      try {
        for (let i = 0; i < context.length; i++) {
          try {
            if (isBase64Image(context[i].page_content)) {
              const imageSource = context[i].metadata['source'];
              const fileName = imageSource.split('\\').pop().split('/').pop();
              images.push({ src: context[i].page_content, source: fileName });
            }
            else {
              const pathFile = context[i].metadata['source']
              //si el pathfile es una URL dejarla asi 
              if (pathFile.startsWith('http')) {
                reference.push(pathFile);
                continue;
              } else {
                const fileName = pathFile.split('\\').pop().split('/').pop();
                if (!fileNameSet.has(fileName)) {
                  fileNameSet.add(fileName);
                }
              }
            }
          } catch (error) {
            console.error("Error al convertir el contexto en imagenes:", error);
          }
        }
      } catch (error) {
        console.error("Error al convertir el contexto en imagenes:", error);
      }

      // Agregar la respuesta del backend a los mensajes
      const user_message_id = data.answer.user_message_id;
      const assistant_message_id = data.answer.assistant_message_id;
      const messages_new = [
        ...messages,
        { id: user_message_id, sender: "user", message: newMessage, chat_history_id: chat_history_id } as ChatMessage,
        { id: assistant_message_id, sender: "assistant", message: answer, images: images, reference: reference, chat_history_id: chat_history_id } as ChatMessage
      ];
      console.log(messages_new)

      updateMessages(messages_new);

    } catch (error) {
      console.error("Error al manejar el streaming de OpenAI:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const fetchDocuments = async (page: number, search: string) => {
    setIsDocumentsLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${ENDPOINTS.DOCUMENT_RAG}?page=${page}&search=${search}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    const data: DocumentRAG[] = await response.json();
    if (data.length === 0) {
      setHasMoreData(false);
    }
    if (page === 1) {
      setDocuments(data);
    } else {
      setDocuments(prevDocuments => [...prevDocuments, ...data]);
    }
    setIsDocumentsLoading(false);
  };

  const handleScroll = () => {
    if (hasMoreData && !isDocumentsLoading) {
      setPage(prevPage => prevPage + 1);
    }
  };


  useEffect(() => {
    const interval = setInterval(() => {
      if (hasMoreData && !isDocumentsLoading) {
        setPage(prevPage => prevPage + 1);
      }
    }, 40000);

    return () => clearInterval(interval);
  }, [hasMoreData, isDocumentsLoading]);



  return (

    <PanelGroup direction="horizontal">
      <Panel defaultSize={50} minSize={20} maxSize={80}>
        <header className="bg-slate-800/80 backdrop-blur-md text-white flex justify-between items-center fixed top-0 left-0 w-full z-10">

          <div className="flex items-end px-4">
            <img src={logoUrl} alt="logo" className="h-10 w-10" />
            <h2 className="pl-2 text-lg font-medium text-slate-800 dark:text-slate-200">{nameOfCompany}</h2>
          </div>

          <div
            className="handle relative"
            style={{ transition: 'transform 0.2s ease' }}
          >
            <button
              onClick={togglePanelVisibility}
              className="focus:outline-none p-2 hover:bg-slate-700 rounded-full transition duration-200 ease-in-out"
            >
              {isPanelVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              )}
            </button>
          </div>
        </header>
        <div className="flex h-[calc(100vh-50px)] w-full flex-col rounded-lg mt-12">

          <PromptMessageComponent messages={messages} lastMessageRef={lastMessageRef} isLoading={isLoading} />
          <PromptInputComponent
            onSendMessage={handleSendMessage}
            promptTemplates={promptTemplates}
            setPromptTemplates={setPromptTemplates}
            isLoading={isLoading} />
        </div>
      </Panel>
      {isPanelVisible ? (
        <CustomPanelResizeHandle >

        </CustomPanelResizeHandle>
      ) : (
        <CustomPanelResizeHandle style={{ display: 'none' }} />
      )}

      {isPanelVisible && (
        <Panel defaultSize={50} minSize={20} maxSize={80}>
          <div className="flex h-[calc(100vh-50px)] w-full flex-col rounded-lg mt-12">
            <CardList
              documents={documents}
              fetchDocuments={fetchDocuments}
              handleScroll={handleScroll}
              isDocumentsLoading={isDocumentsLoading}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </Panel>
      )}
    </PanelGroup>

  );

};

export default ChatComponent;