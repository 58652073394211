import React, { useState } from "react";
import PromptTemplateDialog from './PromptComponets/PromptTemplateDialog';
import { PromptTemplate } from '../Entity/PromptTemplate';
import HistoryComponent from './HistoryComponent';
import { ChatMessage } from "../Entity/ChatMessage";
import PromptListComponent from "./PromptComponets/PromptListComponent";
import ENDPOINTS from '../config';
import { Link } from 'react-router-dom';
import UserSettingsModal from "./UserSettingsModal";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

interface PromptSideTemplateDialogProps {
  onSave: (template: PromptTemplate) => void;
  updateMessages: (messages: ChatMessage[]) => void;
  setHistoryId: React.Dispatch<React.SetStateAction<number | null>>; // Añadir la prop para setHistoryId
  updatePromptTemplates: () => Promise<void>;
  promptTemplates: PromptTemplate[];
}

const SidebarComponent: React.FC<PromptSideTemplateDialogProps> = ({ onSave, updateMessages, setHistoryId, updatePromptTemplates, promptTemplates }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(true);
  const [isPromptListVisible, setIsPromptListVisible] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<PromptTemplate | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { t } = useTranslation();




  const toggleHistoryVisibility = () => {
    setIsHistoryVisible(true);
    setIsPromptListVisible(false); // Ocultar la lista de prompts si se muestra el historial
  };

  const togglePromptListVisibility = () => {
    setIsPromptListVisible(true);
    setIsHistoryVisible(false); // Ocultar el historial si se muestra la lista de prompts
  };

  const handlePromptClick = (template: PromptTemplate) => {
    setSelectedPrompt(template);
    setIsDialogOpen(true);
  };

  const handleHistoryClick = (messages: ChatMessage[], historyId: number) => {
    updateMessages(messages);
    setHistoryId(historyId);
    console.log('History clicked', messages, historyId);
  };

  const handleNewPromptClick = () => {
    setSelectedPrompt(null);
    setIsDialogOpen(true);
  };

  const handleNewChatClick = () => {
    setHistoryId(null);
    updateMessages([]);
  }



  const handleSave = async (template: PromptTemplate) => {
    if (selectedPrompt && selectedPrompt.id) {
      // Update existing prompt template
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(ENDPOINTS.PROMPT_TEMPLATE(selectedPrompt.id), {
          method: 'PUT',
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(template),
        });

        if (response.ok) {
          console.log('Prompt template updated successfully');
          updatePromptTemplates();
        } else {
          console.error('Failed to update prompt template');
        }
      } catch (error) {
        console.error('Error updating prompt template:', error);
      }
    } else {
      // Logic to create a new prompt template can go here
      onSave(template);
    }
    setIsDialogOpen(false);
    setSelectedPrompt(null);
  };


  const handleLogout = () => {
    // Lógica para realizar el logout aquí
    localStorage.removeItem("token"); // Elimina el token del localStorage u otro almacenamiento
    window.location.href = "/login"; // Redirige a la página de inicio de sesión
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language).then(() => {
      console.log('Selected language:', language);
      setIsPopupOpen(false);
    });
  };

  return (
    <aside className="flex">

      {isDialogOpen && (
        <PromptTemplateDialog
          onSave={handleSave}
          onClose={() => setIsDialogOpen(false)}
          iName={selectedPrompt ? selectedPrompt.name : ''}
          iDescription={selectedPrompt ? selectedPrompt.description : ''}
          iContent={selectedPrompt ? selectedPrompt.content : ''}
        />
      )}
      <UserSettingsModal // Incluye el componente UserSettingsModal
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        onLanguageChange={handleLanguageChange}
      />


      <div
        className="sidebar-container hide-sidebar-mobile  h-auto flex w-60 flex-col  bg-slate-50 pt-8 dark:border-slate-700 dark:bg-slate-900  sm:w-64"
      >        

        <div className="mx-2 mt-3 pt-4 ">
          <button
            onClick={handleNewChatClick}
            className="flex w-full gap-x-4 rounded-lg border border-slate-300 p-4 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-blue-500 focus:outline-none dark:border-slate-700 dark:text-slate-200 dark:hover:bg-blue-600"

          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 5l0 14"></path>
              <path d="M5 12l14 0"></path>
            </svg>
            {t('sidebar.new_chat')}
          </button>
        </div>

        {isHistoryVisible && <HistoryComponent onHistoryClick={handleHistoryClick} />}
        {isPromptListVisible && <PromptListComponent promptTemplates={promptTemplates} onPromptClick={handlePromptClick} onNewPromptClick={handleNewPromptClick} />}


        <div className=" w-full flex-grow h-auto">
          <div
            className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-gray-500 focus:outline-none dark:text-slate-200 dark:hover:bg-gray-600"
            onClick={toggleHistoryVisibility}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"
              ></path>
              <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path>
            </svg>
            {t('sidebar.chat_history')}
            <div className="ml-auto">
              <button
                className="flex items-center gap-x-2 rounded-lg px-2 py-1 text-sm font-medium text-white  hover:bg-blue-600 focus:outline-none"
                onClick={handleNewChatClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5v14m-7-7h14"></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-gray-500 focus:outline-none dark:text-slate-200 dark:hover:bg-gray-600"
            onClick={togglePromptListVisibility}
          >
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              className="h-6 w-6"
            >
              <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z" />
            </svg>
            {t('sidebar.prompt_template')}
            <div className="ml-auto">
              <button
                className="flex items-center gap-x-2 rounded-lg px-2 py-1 text-sm font-medium text-white  hover:bg-blue-600 focus:outline-none"
                onClick={handleNewPromptClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5v14m-7-7h14"></path>
                </svg>
              </button>
            </div>
          </div>
          {isPopupOpen && (
            <div className="absolute  left-1 bottom-4 mt-2 mr-4 w-48 bg-white border border-gray-200 dark:bg-gray-900 dark:border-gray-700 rounded-lg shadow-lg z-10">

              <div className="py-1">
                <button
                  onClick={() => setIsSettingsModalOpen(true)} // Abre el UserSettingsModal al hacer clic en Profile
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 focus:outline-none dark:text-slate-200 dark:hover:bg-blue-600 dark:hover:text-white"
                >
                  {t('user_settings.title')}
                </button>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 focus:outline-none dark:text-slate-200 dark:hover:bg-blue-600 dark:hover:text-white"
                >
                  {t('sidebar.logout')}
                </button>
              </div>
            </div>
          )}
          <button
            className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200  focus:outline-none dark:text-slate-200 dark:hover:bg-gray-600"
            onClick={() => setIsPopupOpen(!isPopupOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
              <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
              <path
                d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"
              ></path>
            </svg>

            {t('sidebar.user')}
            <div className="ml-auto">
              <button
                className="flex items-center gap-x-2 rounded-lg px-2 py-1 text-sm font-medium text-white  hover:bg-blue-600 focus:outline-none"
                onClick={() => setIsPopupOpen(!isPopupOpen)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5v14m-7-7h14"></path>
                </svg>
              </button>
            </div>
          </button>

        </div>
      </div>
    </aside>
  );
};

export default SidebarComponent;
