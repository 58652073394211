import React from 'react';
import { IconButton } from '@mui/material'; // Importamos IconButton
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Icono de flecha de regreso
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import pt_PT from "@react-pdf-viewer/locales/lib/fr_FR.json";

interface PDFModalProps {
    open: boolean;
    onClose: () => void;
    documentUrl: string;
    documentName: string;
}

const PDFViewer: React.FC<PDFModalProps> = ({ open, onClose, documentUrl, documentName }) => {
    const lowerCaseDocumentName = documentName.toLowerCase();

    const isPDF = lowerCaseDocumentName.endsWith('.pdf');
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(lowerCaseDocumentName);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [],
    });

    return (
        <div className="relative w-full mb-5">
            {/* Header con el botón de regreso */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <IconButton onClick={onClose} color="primary" aria-label="Regresar a la lista">
                    <ArrowBackIcon /> {/* Icono de regreso */}
                </IconButton>
                <h1 className="text-xl font-bold text-slate-800 dark:text-slate-200" style={{ marginLeft: '10px' }}>
                    {documentName}
                </h1>
            </div>

            {/* Visor del documento */}
            {isPDF ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
                    <div style={{ height: '100vh', overflow: 'auto' }}>
                        <Viewer
                            theme="dark"
                            fileUrl={documentUrl}
                            localization={pt_PT}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </div>
                </Worker>
            ) : isImage ? (
                <div style={{ height: '90vh', overflow: 'auto' }}>
                <img
                    src={documentUrl}
                    alt={documentName}
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
                 </div>
            ) : (
                <div style={{ height: '90vh', overflow: 'auto' }}>
                <iframe
                    title={documentName}
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(documentUrl)}&embedded=true`}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    sandbox="allow-scripts allow-same-origin allow-popups"
                />
                </div>
            )}
        </div>
    );
};

export default PDFViewer;
